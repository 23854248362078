import { Box } from '@mui/material';
import React from 'react';
import { BounceLoader } from 'react-spinners';

const LoadingScreen = () => (
  <Box
    sx={{
      backgroundColor: 'background.paper',
      minHeight: '100%',
      display: 'grid',
      placeItems: 'center',
    }}
  >
    <BounceLoader color="#5664d2" loading size={150} />
  </Box>
);

export default LoadingScreen;
