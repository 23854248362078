export type OrderRequestType = {
  store_id: number;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  order_items: OrderItemType[];
  order_source: OrderSourceEnum;
};

export type OrderItemType = {
  product_id: number;
  size: number;
};

export enum OrderSourceEnum {
  web = 'O',
  mobile = 'M',
}

export type OrderResponseType = {
  order_id: number;
  id: string;
  order_source: string;
  customer_name: string | null;
  customer_email: string | null;
  customer_phone: string | null;
  order_status: string; // enum?
  created_at: string | Date;
  updated_at: string | Date;
  store_id: number;
  order_items: OrderFullItemType[];
};

export type OrderFullItemType = OrderItemType & {
  id: string;
  order_id: string;
  created_at: string | Date;
  updated_at: string | Date;
};
