import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import React, { FC } from 'react';

type PropsType = {
  price: number;
  discountedPrice: number;
  style?: any;
  variantPrice?: Variant;
  variantDiscountedPrice?: Variant;
};

const Price: FC<PropsType> = ({
  price,
  discountedPrice,
  style,
  variantDiscountedPrice,
  variantPrice,
}) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    gap={2}
    style={style}
  >
    {discountedPrice !== undefined && (
      <Typography
        variant={variantDiscountedPrice}
        sx={{ color: 'red', fontWeight: 'bold' }}
      >
        {Number(discountedPrice).toLocaleString('pl-PL', {
          minimumFractionDigits: 2,
        })}{' '}
        zł
      </Typography>
    )}
    {price !== undefined && discountedPrice !== price && (
      <Typography
        variant={variantPrice}
        sx={{ color: 'gray', textDecoration: 'line-through' }}
      >
        {Number(price).toLocaleString('pl-PL', {
          minimumFractionDigits: 2,
        })}{' '}
        zł
      </Typography>
    )}
  </Box>
);

export default Price;
