import { baseUrl } from '../../config/api/constants';

const makeOrderEndpoint = `${baseUrl}/v1/orders`;

const confirmOrderEndpoint = (uuid: string) =>
  `${baseUrl}/v1/order/confirm/${uuid}`;

const fetchOrderEndpoint = (uuid: string) => `${baseUrl}/v1/order/${uuid}`;

export { confirmOrderEndpoint, fetchOrderEndpoint, makeOrderEndpoint };
