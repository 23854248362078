import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { fetchShopList } from '../services/shop';

const useShopList = () => {
  const [filter, setFilter] = useState();
  const { data, isLoading } = useQuery(
    ['fetchShopList', filter],
    () => fetchShopList(filter),
    {
      onError: () =>
        toast.error('Nie udało się pobrać listy dostępnych sklepów', {
          autoClose: 3000,
        }),
    },
  );

  return {
    shopList: data,
    isLoading,
    filter,
    setFilter,
  };
};

export default useShopList;
