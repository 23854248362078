import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pl from './pl.json';

enum AppLocals {
  PL = 'pl',
}

export const resources = {
  [AppLocals.PL]: {
    translation: pl,
  },
} as const;

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: AppLocals.PL,
  resources,
  fallbackLng: AppLocals.PL,
  simplifyPluralSuffix: true,

  interpolation: {
    escapeValue: false,
    format(value, rawFormat, lng) {
      const [format, ...additionalValues] =
        rawFormat?.split(',').map((v) => v.trim()) || [];

      if (format === 'price') {
        return new Intl.NumberFormat(lng, {
          style: 'currency',
          currency: additionalValues[0],
          useGrouping: false,
        }).format(value);
      }

      if (format === 'priceWithoutComa') {
        return new Intl.NumberFormat(lng, {
          style: 'currency',
          currency: additionalValues[0],
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          useGrouping: false,
        }).format(value);
      }

      if (format === 'priceRange') {
        const [firstValue, secondValue] = value.split('-');

        return `${firstValue} - ${new Intl.NumberFormat(lng, {
          style: 'currency',
          currency: additionalValues[0],
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          useGrouping: false,
        }).format(secondValue)}`;
      }

      return value;
    },
  },
});

export default i18n;
