import { Box, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const badgeColors = {
  new: '#4C4D5C',
  discount: '#C00D0D',
};

export enum BadgeEnum {
  New = 'new',
  Discount = 'discount',
}

type BadgeProps = {
  type: BadgeEnum;
  text?: string;
};

const Badge: FC<BadgeProps> = ({ text, type }) => {
  const { t } = useTranslation();

  const badgeColor = useMemo(
    () =>
      type === BadgeEnum.Discount ? badgeColors.discount : badgeColors.new,
    [type],
  );

  const badgeText = text ? `-${text}%` : t('Components.Badge.new');

  return (
    <Box
      sx={{
        display: 'inline-block',
        textAlign: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: 24,
        maxWidth: 120,
        padding: '4px',
        borderRadius: '4px',
        margin: '4px',
        backgroundColor: badgeColor,
      }}
    >
      <Typography variant="body2" sx={{ color: '#ffffff' }}>
        {badgeText}
      </Typography>
    </Box>
  );
};

export default Badge;
