import { genericGet, genericPost } from '../../config/api/apiMethods';
import { OrderRequestType, OrderResponseType } from '../../types/order';
import {
  confirmOrderEndpoint,
  fetchOrderEndpoint,
  makeOrderEndpoint,
} from './endpoints';

const makeOrder = (data: OrderRequestType) =>
  genericPost(makeOrderEndpoint, data);

const fetchOrder = (uuid: string) =>
  genericGet<OrderResponseType>(fetchOrderEndpoint(uuid));

const confirmOrder = (uuid: string) => genericGet(confirmOrderEndpoint(uuid));

export { confirmOrder, fetchOrder, makeOrder };
