import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

type PropsType = {
  children: ReactNode;
};

const ReactQueryClientProvider = ({ children }: PropsType) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
export default ReactQueryClientProvider;
