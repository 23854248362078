/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductType } from '../../types/product';

export type ShoppingCardSliceStateType = {
  product: ProductType | null;
};

const initialState: ShoppingCardSliceStateType = {
  product: null,
};

const viewProductSlice = createSlice({
  name: 'viewProduct',
  initialState,
  reducers: {
    saveProduct: (state, action: PayloadAction<ProductType>) => {
      state.product = action.payload;
    },
  },
});

export const { saveProduct } = viewProductSlice.actions;
export default viewProductSlice.reducer;
