/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ViewSliceStateType = {
  showSuccessEvent: boolean;
  showOrderSuccess: boolean;
};

const initialState: ViewSliceStateType = {
  showSuccessEvent: false,
  showOrderSuccess: false,
};

const viewSlice = createSlice({
  name: 'viewProduct',
  initialState,
  reducers: {
    runSuccessEvent: (state, action: PayloadAction<boolean>) => {
      state.showSuccessEvent = action.payload;
    },
    runOrderSuccess: (state, action: PayloadAction<boolean>) => {
      state.showOrderSuccess = action.payload;
    },
    clearView: (state) => {
      state = initialState;
    },
  },
});

export const { runSuccessEvent, runOrderSuccess, clearView } =
  viewSlice.actions;
export default viewSlice.reducer;
