import Lottie, { LottieOptions } from 'lottie-react';
import * as React from 'react';

type Props = {
  animationSource?: LottieOptions['animationData'];
  style?: any;
  loop?: boolean;
};

const LottieView = ({ animationSource, style, loop = true }: Props) => {
  const defaultStyle = {
    display: 'flex',
    flex: 0.3,
    alignItems: 'center',
    height: 220,
  };

  return (
    <div style={style || defaultStyle}>
      <Lottie autoplay loop={loop} animationData={animationSource} />
    </div>
  );
};

export default LottieView;
