import { Box, Button, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';

import {
  clearShoppingCard,
  expandShoppingCard,
} from '../../store/reducers/shoppingCard';
import { useAppDispatch } from '../../store/store';
import AnimatedButton from '../../theme/components/AnimatedButton';

type Props = {
  total: string;
  isEmpty: boolean;
  visible: boolean;
};

const ShoppingCardActionButtons: FC<Props> = ({ total, isEmpty, visible }) => {
  const dispatch = useAppDispatch();

  const handleCheckout = useCallback(() => {
    dispatch(expandShoppingCard(true));
  }, []);

  const handleClearShoppingCard = useCallback(() => {
    dispatch(clearShoppingCard());
  }, [dispatch]);

  return (
    <Box style={styles.actionContainer}>
      <Typography variant="h6" style={styles.totalPriceText}>
        Razem:{' '}
        {Number(total).toLocaleString('pl-PL', {
          minimumFractionDigits: 2,
        })}{' '}
        zł
      </Typography>
      {visible && (
        <Box>
          <Box style={styles.buttonContainer}>
            <AnimatedButton
              animation="shake"
              style={{ minWidth: 200 }}
              variant="contained"
              color="primary"
              onClick={handleCheckout}
              disabled={isEmpty}
            >
              Realizuj zamówienie
            </AnimatedButton>
          </Box>
          {!isEmpty && (
            <>
              <Typography
                textAlign="center"
                variant="body2"
                style={styles.betweenButtonsText}
              >
                lub
              </Typography>
              <Box style={styles.buttonContainer}>
                <Button
                  style={{ minWidth: 200 }}
                  variant="contained"
                  color="secondary"
                  onClick={handleClearShoppingCard}
                >
                  Wyczyść koszyk
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
      <Typography variant="body2" style={styles.infoText}>
        Odbiór w sklepie w ciągu 3 dni roboczych. Płatność przy odbiorze.
      </Typography>
    </Box>
  );
};

const styles = {
  totalPriceText: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
  betweenButtonsText: {
    padding: 8,
  },
  actionContainer: {
    borderTop: '2px solid black',
    padding: 16,
  },
  infoText: {
    marginTop: 8,
    fontStyle: 'italic',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default ShoppingCardActionButtons;
