import { CircularProgress } from '@mui/material';
import React, { FC } from 'react';

type PropsType = {
  size: string | number;
  style?: React.CSSProperties;
  color1?: string;
  color2?: string;
};

const Loader: FC<PropsType> = ({
  color1 = '#4C4D5C',
  color2 = '#BEBFC9',
  size,
  style,
}) => (
  <>
    <svg width={0} height={0}>
      <defs>
        <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={color1} />
          <stop offset="100%" stopColor={color2} />
        </linearGradient>
      </defs>
    </svg>
    <CircularProgress
      style={style}
      size={size}
      sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
    />
  </>
);

export default Loader;
