import { createTheme } from '@mui/material';

import colors from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.gray['900'],
    },
    secondary: {
      main: colors.gray['50'],
    },
    error: {
      main: colors.brandRed,
    },
    text: {
      primary: colors.gray['900'],
    },
  },
  typography: {
    fontFamily: `'Lato', sans-serif`,
    h1: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 700,
      color: colors.gray['900'],
    },
    h2: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 700,
      color: colors.gray['900'],
    },
    h3: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 700,
      color: colors.gray['900'],
    },
    h4: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 700,
      color: colors.gray['900'],
    },
    h5: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 700,
      color: colors.gray['900'],
    },
    h6: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 700,
      color: colors.gray['900'],
    },
    subtitle1: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 500,
      color: colors.gray['900'],
    },
    subtitle2: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 500,
      color: colors.gray['900'],
    },
    body1: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 400,
      color: colors.gray['900'],
    },
    body2: {
      fontFamily: `'Lato', sans-serif`,
      fontWeight: 300,
      color: colors.gray['900'],
    },
  },
});

export default theme;
