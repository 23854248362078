import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

import colors from '../theme/colors';

const StyledCloseIcon = styled(CloseIcon)(() => ({
  transition: 'transform 0.3s ease-in-out',
  color: colors.gray['900'],
}));

const AnimatedIconButton = styled(IconButton)(() => ({
  '&:hover .MuiSvgIcon-root': {
    transform: 'rotate(90deg) scale(1.2)',
  },
}));

type PropsType = {
  onPress: () => void;
  size: number;
  style?: any;
};

const CloseButton: FC<PropsType> = ({ onPress, size, style }) => (
  <AnimatedIconButton style={style} onClick={onPress}>
    <StyledCloseIcon style={{ width: size, height: size }} />
  </AnimatedIconButton>
);

export default CloseButton;
