import { combineReducers } from '@reduxjs/toolkit';

import filtersReducer from './reducers/filters';
import shoppingCardReducer from './reducers/shoppingCard';
import userReducer from './reducers/user';
import viewReducer from './reducers/view';
import viewProductReducer from './reducers/viewProduct';

const rootReducer = combineReducers({
  user: userReducer,
  shoppingCard: shoppingCardReducer,
  viewProduct: viewProductReducer,
  view: viewReducer,
  filters: filtersReducer,
});

export default rootReducer;
