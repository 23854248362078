import { Button, ButtonProps, keyframes, styled } from '@mui/material';
import React from 'react';

export const shakeAnimation = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
`;

export const fade = keyframes`
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

export const scaleWithFade = keyframes`
  0% { transform: scale(0.95); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
`;

const Animated = styled(Button)<{
  animationType: 'shake' | 'fade' | 'scale' | 'none';
}>(({ animationType }) => ({
  '&:hover': {
    animation:
      // eslint-disable-next-line no-nested-ternary
      animationType === 'shake'
        ? `${shakeAnimation} 0.5s ease-in-out`
        : // eslint-disable-next-line no-nested-ternary
        animationType === 'fade'
        ? `${fade} 0.5s ease-in-out`
        : animationType === 'scale'
        ? `${scaleWithFade} 0.5s ease-in-out`
        : 'none', // No animation if 'none'
  },
}));

interface PropsType extends ButtonProps {
  animation?: 'shake' | 'fade' | 'scale' | 'none';
}

const AnimatedButton: React.FC<PropsType> = ({
  animation = 'none',
  children,
  ...props
}) => (
  <Animated animationType={animation} {...props}>
    {children}
  </Animated>
);

export default AnimatedButton;
