import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { confirmOrder, fetchOrder, makeOrder } from '../services/order';
import { runOrderSuccess, runSuccessEvent } from '../store/reducers/view';
import { useAppDispatch } from '../store/store';
import { OrderRequestType, OrderResponseType } from '../types/order';

const useOrder = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderResponseType>();
  const dispatch = useAppDispatch();

  const handleMakeOrder = useCallback(
    async (data: OrderRequestType) => {
      setIsLoading(true);
      await makeOrder(data)
        .then(() => {
          dispatch(runOrderSuccess(true));
          dispatch(runSuccessEvent(true));
        })
        .catch(() => {
          toast.error('Nie udało się złożyć zamówienia');
          setIsError(true);
        });
      setIsLoading(false);
    },
    [dispatch],
  );

  const handleConfirmOrder = useCallback(
    async (uuid: string) => {
      setIsLoading(true);
      await confirmOrder(uuid)
        .then(() => {
          dispatch(runSuccessEvent(true));
        })
        .catch(() => {
          toast.error('Nie udało się złożyć zamówienia');
          setIsError(true);
        });
      setIsLoading(false);
    },
    [dispatch],
  );

  const handleFetchOrder = useCallback(async (uuid: string) => {
    await fetchOrder(uuid)
      .then((response: OrderResponseType) => {
        setOrder(response);
      })
      .catch(() => {
        toast.error('Nie udało się złożyć zamówienia');
        setIsError(true);
      });
  }, []);

  return {
    handleMakeOrder,
    handleConfirmOrder,
    handleFetchOrder,
    isLoading,
    isError,
    order,
  };
};

export default useOrder;
