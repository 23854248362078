import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import * as React from 'react';
import { useEffect } from 'react';

import Animation from '../assets/animations/successAnimation.json';
import { runSuccessEvent } from '../store/reducers/view';
import { Store, useAppDispatch, useAppSelector } from '../store/store';

const SuccessEvent = () => {
  const styles = {
    mainContainer: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      position: 'fixed',
      zIndex: 1000,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
  };

  const dispatch = useAppDispatch();
  const showSuccessEvent = useAppSelector(
    (state: Store) => state.view.showSuccessEvent,
  );

  useEffect(() => {
    if (showSuccessEvent)
      setTimeout(() => dispatch(runSuccessEvent(false)), 4000);
  }, [dispatch, showSuccessEvent]);

  if (!showSuccessEvent) return null;

  return (
    <Box sx={styles.mainContainer}>
      <Lottie
        autoplay
        loop={false}
        animationData={Animation}
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  );
};

export default SuccessEvent;
