import axios from 'axios';

import { setLoginStatus, setSessionStatus } from '../../store/reducers/user';
import store, { Store, useAppSelector } from '../../store/store';
import { baseUrl } from './constants';

const apiClient = axios.create({
  baseURL: baseUrl,
  // withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  (res) => res,
  (err) => {
    const userState = useAppSelector((state: Store) => state.user);
    if (err.response.status === 401 && userState.isLogin) {
      store.dispatch(setSessionStatus(false));
      store.dispatch(setLoginStatus(false));
    }
    throw err;
  },
);

export default apiClient;
