import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

import CloseButton from '../../CloseButton';

const MobileAppBanner: FC = () => {
  const [isVisible, setVisible] = useState(true);

  if (!isMobile || !isVisible || (!isAndroid && !isIOS)) {
    return null;
  }

  const appLink = isAndroid
    ? 'https://drive.google.com/file/d/15KdJQVwrn4MEONu29D0YlNXO9wlyV26I/view?usp=sharing'
    : 'https://apps.apple.com/us/app/your-app/id1234567890';

  if (isIOS) return null; // TODO: temporary, need to deploy iOS app to AppStore

  return (
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        backgroundColor: '#1976d2',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <CloseButton onPress={() => setVisible(false)} size={28} />
        <Typography variant="body1" color="inherit">
          Pobierz naszą aplikację!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href={appLink}
          target="_blank"
        >
          Pobierz
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBanner;
