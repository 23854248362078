import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { closeShoppingCard } from '../../store/reducers/shoppingCard';
import { clearView } from '../../store/reducers/view';
import { useAppDispatch } from '../../store/store';
import Background from '../../theme/components/Background';
import ShoppingCard from '../ShoppingCard/ShoppingCard';
import SuccessEvent from '../SuccessEvent';

type Props = {
  children: ReactNode;
};

const Main = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearView());
    dispatch(closeShoppingCard());
  }, [dispatch]);
  return (
    <div>
      <Background />
      {children}
      <ShoppingCard />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
      <SuccessEvent />
    </div>
  );
};

export default Main;
