export enum RoutesEnum {
  homeScreen = '/',
  shopScreen = '/sklep',
  promoScreen = '/promo',
  infoScreen = '/informacje',
  searchScreen = '/szukaj',
  productScreen = '/produkt',
  welcomeScreen = '/powitanie',
  confirmOrderScreen = '/confirm',
  logo = '/logo-1671803795.jpg',
}
