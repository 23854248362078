import { Box } from '@mui/material';
import * as React from 'react';
import { isMobile } from 'react-device-detect';

import { backgroundImage, newBackgroundImage } from '../../assets/images';

type PropsType = {
  withImage?: string;
};

const Background = ({ withImage }: PropsType) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      zIndex: -1,
      width: '100vw',
      height: '100vh',
      overflow: 'hidden', // Ensures no scrollbars appear due to background image
    },
    background: {
      backgroundPosition: 'center',
      backgroundSize: isMobile ? 'contain' : 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${backgroundImage})`,
      width: '100%',
      height: '100%',
      position: 'absolute', // Allows it to cover the whole container
    },
    imageContainer: {
      display: 'flex',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: isMobile ? 'auto' : '50%', // Adjust width for mobile
    },
    image: {
      height: 'auto', // Maintain aspect ratio
      width: isMobile ? '100%' : 'auto', // Full width on mobile
      maxHeight: '100%', // Prevent overflow
      objectFit: 'cover', // Maintain aspect ratio
    },
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.background} component="img" src={newBackgroundImage} />
      {!!withImage && (
        <Box sx={styles.imageContainer}>
          <Box sx={styles.image} component="img" src={withImage} />
        </Box>
      )}
    </Box>
  );
};

export default Background;
