import { Box, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import {
  expandShoppingCard,
  toggleShoppingCard,
} from '../../store/reducers/shoppingCard';
import { useAppDispatch } from '../../store/store';
import theme from '../../theme/theme';
import CloseButton from '../CloseButton';

type Props = {
  expand?: boolean;
};

const ShoppingCardHeader: FC<Props> = ({ expand }) => {
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(expandShoppingCard(false));
    if (expand) {
      setTimeout(() => {
        dispatch(toggleShoppingCard());
      }, 500);
    } else dispatch(toggleShoppingCard());
  }, [dispatch, expand]);
  return (
    <Box flexDirection="row" style={styles.headerContainer}>
      <Typography
        style={styles.headerText}
        gutterBottom
        color={theme.palette.text.primary}
      >
        Twój Koszyk
      </Typography>
      <CloseButton onPress={handleClose} size={isMobile ? 28 : 42} />
    </Box>
  );
};

const styles = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 8,
    paddingTop: 16,
  },
};

export default ShoppingCardHeader;
