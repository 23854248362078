/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FiltersRequestType,
  ObjectType,
  PricesType,
} from '../../types/filters';

export type FiltersSliceStateType = {
  filtersTarget?: FiltersRequestType;
  colors: ObjectType;
  materials: ObjectType;
  heelTypes: ObjectType;
  insideMaterials: ObjectType;
  types: ObjectType;
  seasons: ObjectType;
  sizes: string;
  name_search: string;
  magad_index: string;
  prices: PricesType;
  forceCloseFiltersCard: boolean;
};

const initialState: FiltersSliceStateType = {
  filtersTarget: undefined,
  colors: { id: 0, name: '' },
  materials: { id: 0, name: '' },
  heelTypes: { id: 0, name: '' },
  insideMaterials: { id: 0, name: '' },
  types: { id: 0, name: '' },
  seasons: { id: 0, name: '' },
  sizes: '',
  name_search: '',
  magad_index: '',
  prices: { min: '0', max: '10000' },
  forceCloseFiltersCard: false,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    changeFiltersTarget: (state, action: PayloadAction<FiltersRequestType>) => {
      state.filtersTarget = action.payload;
    },
    changeColors: (state, action: PayloadAction<ObjectType>) => {
      state.colors = action.payload;
    },
    changeMaterials: (state, action: PayloadAction<ObjectType>) => {
      state.materials = action.payload;
    },
    changeHeelTypes: (state, action: PayloadAction<ObjectType>) => {
      state.heelTypes = action.payload;
    },
    changeInsideMaterials: (state, action: PayloadAction<ObjectType>) => {
      state.insideMaterials = action.payload;
    },
    changeTypes: (state, action: PayloadAction<ObjectType>) => {
      state.types = action.payload;
    },
    changeSeasons: (state, action: PayloadAction<ObjectType>) => {
      state.seasons = action.payload;
    },
    changeSizes: (state, action: PayloadAction<string>) => {
      state.sizes = action.payload;
    },
    changeNameSearch: (state, action: PayloadAction<string>) => {
      if (/^[0-9]+$/.test(action.payload)) {
        state.name_search = initialState.name_search;
        state.magad_index = action.payload;
      } else {
        state.magad_index = initialState.magad_index;
        state.name_search = action.payload;
      }
    },
    changePrices: (state, action: PayloadAction<PricesType>) => {
      state.prices = action.payload;
    },
    clearFilters: (state) => {
      state.colors = initialState.colors;
      // state.prices = initialState.prices; // without prices
      state.name_search = initialState.name_search;
      state.magad_index = initialState.magad_index;
      state.sizes = initialState.sizes;
      state.seasons = initialState.seasons;
      state.materials = initialState.materials;
      state.insideMaterials = initialState.insideMaterials;
      state.heelTypes = initialState.heelTypes;
    },
    forceCloseFiltersCard: (state, action: PayloadAction<boolean>) => {
      state.forceCloseFiltersCard = action.payload;
    },
  },
});

export const {
  changeFiltersTarget,
  changeHeelTypes,
  changeMaterials,
  changeInsideMaterials,
  changeTypes,
  changePrices,
  changeSeasons,
  changeSizes,
  changeNameSearch,
  changeColors,
  clearFilters,
  forceCloseFiltersCard,
} = filtersSlice.actions;
export default filtersSlice.reducer;
