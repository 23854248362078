import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import AnimationConfirmEmail from '../../assets/animations/orderConfirmEmailAnimation.json';
import {
  clearShoppingCard,
  ProductCardType,
  toggleShoppingCard,
} from '../../store/reducers/shoppingCard';
import { runOrderSuccess } from '../../store/reducers/view';
import { useAppDispatch } from '../../store/store';
import Background from '../../theme/components/Background';
import theme from '../../theme/theme';
import LottieView from '../LottieView';
import ShoppingCardList from './ShoppingCardList';

type Props = {
  orderedProducts: ProductCardType[];
  email: string;
};

const CardContainer = styled('div')(
  ({ theme }) => `
    background-color: white;
    border-radius: 20;
    position: fixed;
    box-shadow: -3px 0px 10px 1px #aaaaaa;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    transition: ${theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen + 500,
    })};
`,
);

const ShoppingCardSuccessOrder = ({ orderedProducts, email }: Props) => {
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(clearShoppingCard());
    dispatch(runOrderSuccess(false));
    dispatch(toggleShoppingCard());
  }, [dispatch]);

  return (
    <CardContainer
      sx={{
        width: '100%',
        overflow: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-around',
      }}
    >
      <Background />
      <Box style={{ flex: 1 }}>
        <Typography textAlign="center" variant="h6" style={styles.headerText}>
          Potwierdź zamówienie!
        </Typography>
        <Typography
          textAlign="center"
          variant="h6"
          style={styles.descriptionText}
        >
          Na podany adres email wysłaliśmy link z potwierdzeniem zamówienia.
        </Typography>
        <Typography textAlign="center" variant="h6" style={styles.mailText}>
          {email}
        </Typography>
        <Box
          style={{
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <LottieView
            style={{ width: '40%' }}
            animationSource={AnimationConfirmEmail}
          />
        </Box>
        <Box style={styles.buttonContainer}>
          <Button
            style={{ minWidth: 200 }}
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Rozumiem
          </Button>
        </Box>
      </Box>
      <Box style={{ flex: isMobile ? 1 : 0.5 }}>
        <Typography textAlign="center" variant="h6" style={styles.headerText}>
          Podsumowanie
        </Typography>
        <Box style={{ width: 350 }}>
          <Box style={{ padding: 16 }}>
            <ShoppingCardList data={orderedProducts} isSummaryView />
          </Box>
        </Box>
      </Box>
    </CardContainer>
  );
};

const styles = {
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 8,
    paddingTop: 16,
    color: theme.palette.text.primary,
  },
  descriptionText: {
    fontSize: 14,
    padding: 8,
    paddingTop: 16,
    color: theme.palette.text.primary,
  },
  mailText: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: 8,
    color: theme.palette.text.primary,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default ShoppingCardSuccessOrder;
