import moment from 'moment';

const checkIsNewProduct = (date: string | Date) => {
  const currentDate = moment();
  const productDate = moment(date);
  const differenceInDays = currentDate.diff(productDate, 'days');

  return differenceInDays < 100;
};

export default checkIsNewProduct;
