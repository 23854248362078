import { lazy, Suspense } from 'react';
import * as React from 'react';

import LoadingScreen from './components/LoadingScreen';
import { RoutesEnum } from './types/routes';

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const HomeScreen = Loadable(lazy(() => import('./screens/HomeScreen')));
const ShopScreen = Loadable(
  lazy(() => import('./screens/ProductList/ProductListScreen')),
);
const SearchScreen = Loadable(
  lazy(() => import('./screens/SearchScreen/SearchScreen')),
);
const WelcomeScreen = Loadable(lazy(() => import('./screens/WelcomeScreen')));
const InfoScreen = Loadable(lazy(() => import('./screens/Info/InfoScreen')));
const ProductScreen = Loadable(
  lazy(() => import('./screens/Product/ProductScreen')),
);
const ConfirmOrderScreen = Loadable(
  lazy(() => import('./screens/ConfirmOrder/ConfirmOrderScreen')),
);

const routes = [
  {
    path: RoutesEnum.homeScreen,
    element: <HomeScreen />,
  },
  {
    path: RoutesEnum.shopScreen,
    element: <ShopScreen />,
  },
  {
    path: RoutesEnum.infoScreen,
    element: <InfoScreen />,
  },
  {
    path: RoutesEnum.searchScreen,
    element: <SearchScreen />,
  },
  {
    path: RoutesEnum.welcomeScreen,
    element: <WelcomeScreen />,
  },
  {
    path: RoutesEnum.productScreen,
    element: <ProductScreen />,
  },
  {
    path: RoutesEnum.confirmOrderScreen,
    element: <ConfirmOrderScreen />,
  },
  {
    path: RoutesEnum.promoScreen,
    element: <ShopScreen isPromo />,
  },
  {
    path: '*',
    // TODO: dodać widok zaślepkowy
    element: (
      <main style={{ padding: '1rem' }}>
        <p>Nic tu nie ma!</p>
      </main>
    ),
  },
];

export default routes;
