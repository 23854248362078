import { Delete } from '@mui/icons-material';
import { Box, Button, List, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { emptyImage } from '../../assets/images';
import {
  deleteProductFromCard,
  ProductCardType,
} from '../../store/reducers/shoppingCard';
import { useAppDispatch } from '../../store/store';
import colors from '../../theme/colors';
import Badge, { BadgeEnum } from '../../theme/components/Badge';
import calculateDiscount from '../../utils/calculateDiscount';
import checkIsNewProduct from '../../utils/checkIsNewProduct';
import Price from '../Price';

type Props = {
  data: ProductCardType[];
  isSummaryView?: boolean;
};

const ShoppingCardList = ({ data, isSummaryView }: Props) => {
  const dispatch = useAppDispatch();
  const itemsCount = data.length;

  const renderItem = useCallback(
    (item: ProductCardType, index: number) => {
      const isLast = itemsCount === index + 1;
      const discount = calculateDiscount(item.price, item.discountedPrice);
      const isNewBadge = checkIsNewProduct(item?.createdAt);

      return (
        <Box
          key={item.id}
          borderBottom={isLast ? 'none' : 'solid'}
          sx={styles.itemContainer}
        >
          <Box
            // TODO: navigate to product details by id, add when EP will be ready
            onClick={() => {}}
            key={item.uuid}
            component="img"
            src={item?.image?.url || emptyImage}
            style={styles.itemPhoto}
          />
          <Box style={{ position: 'absolute', top: 4, left: 4 }}>
            {isNewBadge && <Badge type={BadgeEnum.New} />}
            {discount ? (
              <Badge text={discount.toString()} type={BadgeEnum.Discount} />
            ) : null}
          </Box>
          <Box style={styles.descriptionContainer}>
            <Box style={{ padding: 4, paddingLeft: 16 }}>
              <Typography
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: isSummaryView ? 3 : 2,
                }}
              >
                {item.name}
              </Typography>
              <Price
                price={item.price}
                discountedPrice={item.discountedPrice || 0}
                variantDiscountedPrice="subtitle1"
                variantPrice="caption"
              />
              <Typography
                style={styles.sizeText}
              >{`rozmiar: ${item.size}`}</Typography>
            </Box>
            {!isSummaryView && (
              <Box style={styles.deleteButtonContainer}>
                <Button
                  startIcon={<Delete />}
                  size="small"
                  style={styles.deleteButton}
                  variant="text"
                  color="inherit"
                  onClick={() => dispatch(deleteProductFromCard(item.uuid))}
                >
                  Usuń produkt
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      );
    },
    [dispatch, isSummaryView, itemsCount],
  );

  return <List>{data.map((item, index) => renderItem(item, index))}</List>;
};

const styles = {
  itemContainer: {
    position: 'relative',
    borderColor: 'silver',
    borderWidth: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 4,
    paddingBottom: 4,
  },
  itemPhoto: {
    height: 150,
    width: 'auto',
    maxWidth: 150,
  },
  sizeText: { color: 'silver' },
  descriptionContainer: {},
  deleteButtonContainer: {
    paddingLeft: 8,
    display: 'flex',
  },
  deleteButton: {
    color: colors.brandRed,
  },
};

export default ShoppingCardList;
