import { AxiosRequestConfig, AxiosResponse } from 'axios';

import apiClient from './api';
import { authorizationPrefix } from './constants';

type ValidationError = {
  value: string;
  property: string;
  constraints: {
    isEmail?: string;
    isValidNip?: string;
    isNotEmpty?: string;
    isNumberString?: string;
    isString?: string;
    length?: string;
    matches?: string;
  };
};

type ApiHttpErrorType = {
  response: {
    status: string;
    data?: {
      lockoutTimeout: number;
      errors?: ValidationError[];
    };
  };
};

export const createHeadersWithToken = (token?: string) =>
  token
    ? { Authorization: `${authorizationPrefix} ${token}` }
    : { Authorization: '' };

const genericPost = async <ResponseType>(
  endpoint: string,
  postParameters?: { [key: string]: unknown } | FormData,
  token?: string,
): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);

  return apiClient
    .post(endpoint, postParameters, { headers })
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw new Error(error.response.status);
    });
};

const genericGet = async <ResponseType>(
  endpoint: string,
  config?: AxiosRequestConfig | undefined,
  token?: string,
): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);

  return apiClient
    .get(endpoint, { ...config, headers })
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw new Error(error.response.status);
    });
};

const genericGetWithHeaders = <ResponseType>(
  endpoint: string,
  config?: AxiosRequestConfig | undefined,
  token?: string,
): Promise<Pick<AxiosResponse<ResponseType>, 'data' | 'headers'>> => {
  const headers = createHeadersWithToken(token);

  return apiClient
    .get(endpoint, {
      ...config,
      headers: { ...config?.headers, ...headers },
    })
    .then((response: AxiosResponse<ResponseType>) => ({
      data: response.data,
      headers: response.headers,
    }));
};

const genericPatch = async <ResponseType>(
  endpoint: string,
  postParameters: { [key: string]: unknown },
  token?: string,
): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);

  return apiClient
    .patch(endpoint, postParameters, {
      headers,
    })
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw new Error(error.response.status);
    });
};

const genericDelete = <ResponseType>(
  url: string,
  token?: string,
): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);

  return apiClient
    .delete(url, {
      headers,
    })
    .then((response: AxiosResponse<ResponseType>) => response.data);
};

const queryGet = <ResponseType>(
  endpoint: string,
  query?: any,
): Promise<ResponseType> => {
  const endpointUrl = query ? `${endpoint}?${query}` : endpoint;

  return apiClient
    .get(endpointUrl)
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw new Error(error.response.status);
    });
};

export {
  genericDelete,
  genericGet,
  genericGetWithHeaders,
  genericPatch,
  genericPost,
  queryGet,
};
